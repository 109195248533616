.the-journey-header {
    background-image: url("../../assets/theJourneyHeader.jpg");
    background-size: cover;
    background-position: center;
    height: 40vh;
    margin: 0;
}

.lot-trace-blindman-logo {
    padding-top: 1rem;
    padding-left: 1rem;
}

.root-lot-trace {
    background-color: white;
    height: 100vh;
    width: 100vw
}

.lot-trace-blindman-logo {
    padding-top: 1rem;
    padding-left: 1rem;
}
.more-info-navigation {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.navigation-button-image {
    padding: 1rem
}

.ui.custom.button {
    background-color: #4d8921;
    color: white;
}

.timeline.timeline--animate {
    margin: 0;
}
/*.title {*/
/*    display: none;*/
/*}*/

.title {
    width: 10% !important;
    opacity: 0;
}

.timeline::before {
    left: 10% !important;
}

.entry .body {
    float: left !important;
    width: 90% !important;
}

.section-heading {
    color: #4d8921;
    font-size: larger;
    margin-bottom: 2rem ;
}
.section-text {
    color: #0f0f10;
    font-size: medium;
}

.page-contents-lot-trace {
    padding-top: 5rem;
    width: 50%;
    background-color: #e3e3e3;
}

.button-grid {
    margin: 5rem;
}
.map-segment {
    height: 50vh;
}

.back-to-timeline {
    margin: 5rem
}

.section-row {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.vertical-timeline::before {
    background: #4d8921;
}

.root-lot-not-found {
    background-image: url("../../assets/landingPageBackground.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
}

.not-found-container {
    height: 100%
}

.not-found-segment {
    width: 100%
}

.farmer-count {
    color: #0f0f10;
    font-size: medium;
}

.journey-data-row {
    padding-top: 0;
    padding-bottom: 0;
}

.grid-datapoint {
    font-size: 0.1rem;
    margin-right: 0 !important;
}

.journey-step-headers {
    padding-right: 0 !important;
}

.journey-step-data {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.journey-step-header {
    margin-right: 0 !important;
}
.header-text {
    font-size:7vh;
    text-wrap: none;
}
.mobile-image-slider {
    margin-bottom: 2vh;
}