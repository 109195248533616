.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: "Brewski";
	src: url("../src/assets/Brewski-Original.otf");
}

.sign-up {
	z-index: 1000;
}

.sign-up .message {
	margin-bottom: 25px;
}

.sign-up.message h2 {
	text-align: left;
	font-size: 1.5rem;
	line-height: 1.417;
	font-weight: 700;
	margin-bottom: 0;
}
