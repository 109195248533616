.root-more-info {
    background-color: white;
    height: 100vh;
    width: 100vw
}

.more-info-header {
    background-image: url("../../assets/moreInfoHeader.jpg");
    background-size: cover;
    height: 40vh;
    margin: 0;
    background-position: center bottom;
}

.header-grid {
    height: 100%;
    margin: 0;
}
.more-info-navigation {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.navigation-button-image {
    padding: 1rem
}

.ui.custom.button {
    background-color: #4d8921;
    color: white;
}

.timeline.timeline--animate {
    margin: 0;
}
/*.title {*/
/*    display: none;*/
/*}*/

.title {
    width: 10% !important;
    opacity: 0;
}

.timeline::before {
    left: 10% !important;
}

.entry .body {
    float: left !important;
    width: 90% !important;
}

.section-heading {
    color: #4d8921;
    font-size: larger;
    margin-bottom: 2rem ;
}
.section-text {
    color: #0f0f10;
    font-size: medium;
}

.page-contents {
    padding-top: 5rem;
    width: 50%;
}

.button-grid {
    margin: 5rem;
}
.map-segment {
    height: 70vh;
}

.back-to-timeline {
    margin: 5rem
}

.section-row {
    margin-top: 2rem;
    margin-bottom: 2rem;
}