.root {
    height: 100vh;
    width: 100vw;
    background-image: url("../../assets/landingPageBackground.jpg");
    background-size: cover;
}

.main-grid {
    height: 100vh;
    width: 100vw;
    /*background-image: url("../../assets/landingPageBackground.jpg");*/
    /*background-size: cover;*/
}
.can-image {

}
.social-media {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
}

.grain-discovery {
    position: fixed;
    bottom: 1rem;
    right: 3rem;
    font-size: small;
}

.logo-container {
    width: 100%;
    margin-bottom: 5rem
}

.blindman-logo {
    height: 2vh
}

.gd-logo {
    height: 5vh
}

.content-container {
    width: 100%;
    margin-bottom: 5rem;
}

.enter-id-header {
    color: white;
    font-weight: bold;
}

.enter-id-header-content {
    color: white;
    font-size: 4rem;
}

.trace-number-input {

}

.enter-lot-number-text {
    margin-bottom: 2rem;
    margin-top: 2rem;
}