.the-about-header {
    background-image: url("../../assets/aboutHeader.jpg");
    background-size: cover;
    background-position: center;
    height: 40vh;
    margin: 0;
}

.root-about {
    background-color: white;
    height: 100vh;
    width: 100vw
}

.page-contents-about {
    padding-top: 5rem;
    width: 50%;
}

.lot-trace-blindman-logo {
    padding-top: 1rem;
    padding-left: 1rem;
}

.section-heading-about {
    color: #4d8921;
    font-size: x-large;
    margin-bottom: 2rem ;
}

.section-text-about {
    color: #0f0f10;
    font-size: medium;
}

.about-container-row {
    margin-bottom: 4rem ;
}

.the-about-header-text {
    font-size: 3vh;
    font-weight: bolder;
}